import { Injectable } from '@angular/core';
import moment from "moment";

@Injectable()
export class CookieHandler {


    constructor() { }    

    public createCookie(name: string, value: string, days: number) {
        const date = new Date();
        let expires = "";
        if (!days) {
            days = 1;
        }
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        //let HttpOnly = "; HttpOnly="+httpOnly;
        document.cookie = name + "=" + value + expires + " path=/ ; SameSite=None ; Secure " ; 
    }

    public readCookie(name: string) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    public deleteCookie(name: string) {
        this.createCookie(name, "", -1);
    }

    
    // This is named as cookie but uses localstorage to store data. 
    // The commented code is for localstorage implementation

    // public createLSCookie(name: string, value: string, hours: number) {
    //     //const date = new Date();
    //     if (!hours) {
    //         hours = 24;
    //     }
    //     const expiresAt = moment().add(hours, 'hours');
    //     //date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    //     localStorage.setItem(name, value);
    //     localStorage.setItem(name + 'expires_at', JSON.stringify(expiresAt.valueOf()));
    //     //expires = "; expires=" + date.toUTCString();
    //     //document.cookie = name + "=" + value + expires + "; path=/";
    // }

    // public readLSCookie(name: string) {
    //     let ca = localStorage.getItem(name);
    //     if(this.isLoggedIn(name)) {
    //         if (ca) return ca;
    //     } else {
    //         this.deleteLSCookie(name);
    //     }
    //     return null;
    // }

    // public deleteLSCookie(name: string) {
    //     localStorage.removeItem(name);
    //     localStorage.removeItem(name + "expires_at");
    // }

    // public isLoggedIn(name) {
    //     return moment().isBefore(this.getExpiration(name));
    // }

    // getExpiration(name) {
    //     const expiration = localStorage.getItem(name + "expires_at");
    //     const expiresAt = JSON.parse(expiration);
    //     return moment(expiresAt);
    // }
}