import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface VideoDetail {
  id: number;
  title: string;
  url: string;
}
@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss'],
})
export class VideoLibraryComponent implements OnInit {
  public readonly OriginalVideoList: VideoDetail[] = [
    { id: 1, title: 'SCP-Trainig Module', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
    { id: 2, title: 'Daily Status', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
    { id: 3, title: 'Vectus Task 2', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
    { id: 4, title: 'Vectus Max Limit part-2', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
    { id: 5, title: 'Vectus Max Limit', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
    { id: 6, title: 'Daily Status', url: 'http://localhost:4200/assets/files/TML_SCP.mp4' },
  ];
  public safeURL: SafeResourceUrl;
  public filteredVideoList: VideoDetail[] = [];

  constructor(private _sanitizer: DomSanitizer) {
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
  }

  ngOnInit() {
    this.filteredVideoList = this.OriginalVideoList;
  }
  onSearch(keyword: string) {
    console.log(keyword);
    if (!keyword) {
      this.filteredVideoList = this.OriginalVideoList;
      return;
    }
    this.filteredVideoList = this.OriginalVideoList.filter((el) => el.title.toLocaleLowerCase().match(keyword.toLocaleLowerCase()));
  }
}
