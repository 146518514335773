/**
 * Contains the credentials to login to the application.
 *
 * @export
 * @class Credential
 */
export class Credential {
    username: string;
    password: string;
    loginCurrentSystem: boolean;
    /**
     * Creates an instance of Credential.
     * @param {string} username
     * @param {*} password
     * @memberof Credential
     */
    constructor() {
    }
}