import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
})
export class DocumentLibraryComponent implements OnInit {
  pdfSource = '/assets/files/Asterisk Supply Chain Solutions - Privacy Notice.pdf';

  constructor() {}

  ngOnInit() {}
}
