import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import * as _underscore from 'underscore';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public user_roles: Array<any> = new Array<any>();
  public loading: boolean;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loading = false;
  }

  ngOnInit() {
    this.globalService.setTitle('Asterisk');

    if (this.globalService.showTeamLogos) {
      this.globalService.toolName = "Team Asterisk";
      // this.globalService.headerLogo = "./assets/images/asterisk-header-new.png";
      this.globalService.headerLogo = "./assets/images/asterisk-tml-header.png";

    } else {
      this.globalService.toolName = undefined;
      this.globalService.headerLogo = undefined;
    }

    this.initializeLandingPage();
  }

  private async initializeLandingPage() {
    this.user_roles = this.globalService.user.roles.filter(role => role.type == 'landing' && (role.role_id == this.globalService.user.role_id));
    this.loading = false;
  }

  selectTool(route: any) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
    this.globalService.setSessionStorageItem( "tileInfo", JSON.stringify(route));
    this.globalService.updateTileInfo(route["label"]);
    this.router.navigate([`..${route.role_privilege_route}`], { relativeTo: this.route });
  }
}
