import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AmountConverterPipe } from './pipes/amount-convertor.pipe';
import { NegativeComparerPipe } from './pipes/negative-comparer.pipe'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusySpinnerComponent } from './components/busy-spinner/busy-spinner.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { LoaderSpinnerComponent } from './components/loader-spinner/loader-spinner.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressBarModule } from 'primeng/progressbar';
import { OrderListModule } from 'primeng/orderlist';
import {SplitButtonModule} from 'primeng/splitbutton';


import { NgTableComponent } from './components/ng-table/ng-table.component';
import { NgDropdownComponent } from './components/ng-dropdown/ng-dropdown.component';
import { NgMultiSelectComponent } from './components/ng-multi-select/ng-multi-select.component';
import { SlickTreetableComponent } from './components/slick-treetable/slick-treetable.component';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { TabsModule } from 'ngx-bootstrap';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { SocketService } from './services/socket.service';
import { ChartsModule } from 'ng2-charts';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PowerBiEmbedComponent } from './components/power-bi-embed/power-bi-embed.component';
import { ContextMenuModule, TreeModule } from 'primeng/primeng';
import {SelectButtonModule} from 'primeng/selectbutton';
import { StepsModule } from 'primeng/primeng';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, `./assets/${environment.context_root}i18n/`, ".json");
}

const socketConfig: SocketIoConfig = environment.socketConfig;

@NgModule({
  declarations: [
    PageNotFoundComponent,
    SafeHtmlPipe,
    AmountConverterPipe,
    BusySpinnerComponent,
    UnauthorizedComponent,
    LoaderSpinnerComponent,
    NgTableComponent,
    NgDropdownComponent,
    NgMultiSelectComponent,
    SlickTreetableComponent,
    ProgressBarComponent,
    NegativeComparerPipe,
    PowerBiEmbedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatIconModule,
    DragDropModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    SplitButtonModule,
    PanelMenuModule,
    PanelModule,
    TabViewModule,
    ProgressBarModule,
    CalendarModule,
    DialogModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    AngularSlickgridModule.forRoot(),
    TabsModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    ChartsModule,
    AutoCompleteModule,
    OrderListModule,
    StepsModule,
    MatProgressBarModule,
    ContextMenuModule
  ],
  exports: [
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatIconModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    PageNotFoundComponent,
    BusySpinnerComponent,
    UnauthorizedComponent,
    LoaderSpinnerComponent,
    SafeHtmlPipe,
    AmountConverterPipe,
    TranslateModule,
    TableModule,
    TreeModule,
    SelectButtonModule,
    PanelModule,
    DropdownModule,
    MultiSelectModule,
    SplitButtonModule,
    PanelMenuModule,
    DialogModule,
    NgTableComponent,
    NgDropdownComponent,
    NgMultiSelectComponent,
    SlickTreetableComponent,
    AngularSlickgridModule,
    TabsModule,
    ProgressBarModule,
    TabViewModule,
    ChartsModule,
    CalendarModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    AutoCompleteModule,
    ProgressBarComponent,
    OrderListModule,
    NegativeComparerPipe,
    StepsModule,
    MatProgressBarModule,
    PowerBiEmbedComponent,
    ContextMenuModule
  ],
  providers: [SocketService]
})
export class SharedModule { }
