import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeSecurityQAModalComponent } from '../change-security-qa-modal/change-security-qa-modal.component';
import { ConstantHandler } from '../../handlers/constant-handler';
import { AlertHandler } from '../../handlers/alert-handler';
import { MessageHandler } from '../../handlers/message-handler';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CommonHandler } from '../../handlers/common-handler';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private subscriptions = new Subscription();

  public userFullName: string;
  public userRole: string;
  public date: Date = new Date();
  public activeBU: string;
  public activeTile: string;

  // Remove Below Code Later
  public ssoEnabled = environment.ssoEnabled;

  /**
   * Creates an instance of AppHeader.
   * @param {AuthenticationService} authenticationService
   * @memberof AppHeader
   */
  constructor(private translate: TranslateService, public globalService: GlobalService,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private spinner: NgxSpinnerService) {
  }


  ngOnInit() {
    this.globalService.updateBUDesc(" ");
      this.globalService.updateTileInfo(" ");
    this.userFullName = this.globalService.user.fullname || this.globalService.user.username;
    this.userRole = (this.globalService.user.roles && this.globalService.user.roles.length > 0) ? this.globalService.user.roles[0].role_name : null;
    this.initializeBu();
  }

  private initializeBu() {
    this.subscriptions.add(this.globalService.buDesc.subscribe(resp =>{
          if(resp == " " && this.globalService.getSessionStorageItem('Vehicle')){
            this.activeBU = JSON.parse(this.globalService.getSessionStorageItem('Vehicle')).bu_description;
          }else{
            this.activeBU = resp;
          }
        }))
        this.subscriptions.add(this.globalService.tileInfo.subscribe(resp =>{
          if(resp == " " && this.globalService.getSessionStorageItem('tileInfo')){
            this.activeTile = JSON.parse(this.globalService.getSessionStorageItem('tileInfo')).label; 
          }else{
            this.activeTile = resp;
          }
        }))
      }

  ngAfterViewInit() {
    // TODO: Enable This Code if Change Password is enabled.

    // if (!this.globalService.user.security_question_id && this.globalService.getLocalStorageItem('SECURITY_QUESTION_UPDATED') == null
    //   && this.globalService.globalParams['security_question_disabled'] == null) {
    //   setTimeout(() => {
    //     this.openChangeSecurityQADialog();
    //   }, 0);
    // }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.dialog.closeAll();
  }


  private openChangeSecurityQADialog() {
    const dialogRef = this.dialog.open(ChangeSecurityQAModalComponent, {
      disableClose: true,
      closeOnNavigation: false,
      position: ConstantHandler.commonDialogPosition,
      width: '500px',
      height: ConstantHandler.commonDialogDimension.HEIGHT,
      data: {
        user: CommonHandler.getCopy(this.globalService.user)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      AlertHandler.SuccessAlert(MessageHandler.INFO_DATA_SAVED);
    });
  }

  /**
   * Action when a user logs out.
   *
   * @memberof AppHeader
   */
  logout() {
    this.spinner.show();
    this.authService.logout({})
      .subscribe(
        result => {
          this.spinner.hide();
          // if (result && result.response == "SUCCESS") {
          if (environment.msalEnabled) {
            this.globalService.deleteUserSession();
            this.router.navigate(['auth/msl-logout']);
          } else {
            this.router.navigate(['auth/login']);
            this.globalService.deleteUserSession();
          }
          // } else {
          //   AlertHandler.ErrorAlert(result.message);
          //   this.router.navigate(['auth/login']);
          //   this.globalService.deleteUserSession();
          // }
        },
        error => {
          this.spinner.hide();
          if (environment.msalEnabled) {
            this.globalService.deleteUserSession();
            this.router.navigate(['auth/msl-logout']);
          } else {
            this.router.navigate(['auth/login']);
            this.globalService.deleteUserSession();
          }
          // AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
        });
  }

  /**
   * Toggle fullscreen view for the page
   *
   * @memberof AppHeader
   */
  toggleFullscreen() {
    if (document['fullScreen'] || document['mozFullScreen'] || document['webkitIsFullScreen']) {
      this._hideFullScreen();
    } else {
      this._showFullScreen();
    }
  }

  /**
   * Show full screen for the page
   *
   * @private
   * @memberof AppHeader
   */
  private _showFullScreen() {
    let elem: any = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /**
   * Toggle fullscreen back to normal window size
   *
   * @private
   * @memberof AppHeader
   */
  private _hideFullScreen() {
    if (document['exitFullscreen']) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) { /* Firefox */
      document.mozCancelFullScreen();
    // } else if (document['webkitExitFullscreen']) { /* Chrome, Safari and Opera */
      // document.();
    } else if (document['msExitFullscreen']) { /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  public navigateToHome() {
    if (!this.ssoEnabled) {
      this.activeBU = this.activeTile = null;
      this.globalService.updateTileInfo(" ");
      this.globalService.deleteSessionStorageItem( "tileInfo");
      this.globalService.toolName = "Team Asterisk";
      this.router.navigate(['app']);
    }
  }

  public goToMyProfile() {
    this.router.navigate(['app/my-profile']);
  }

  public goToPrivacyStatement() {
    this.router.navigate(['app/privacy']);
  }

  public goToUserGuide() {
    this.router.navigate(['app/user-guide']);
  }
}
