import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, HostListener, OnDestroy } from '@angular/core';
import { SlickTreetableColumn } from '../../interfaces/slick-treetable-column';
import { Column, GridOption, AngularGridInstance, AngularUtilService, Editors, FieldType, Aggregators, Grouping, GroupTotalFormatters } from 'angular-slickgrid';
import { CommonHandler } from 'src/app/core/handlers/common-handler';
import { KeyGenerator } from 'src/app/core/handlers/key-generator';
import { SlickTreeTableOption } from '../../interfaces/slick-treetable-option';

@Component({
  selector: 'app-slick-treetable',
  templateUrl: './slick-treetable.component.html',
  styleUrls: ['./slick-treetable.component.scss']
})
export class SlickTreetableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dataSet: Array<any>;
  @Input() columnDefinitions: Array<SlickTreetableColumn>;
  @Input() customOptions: SlickTreeTableOption;

  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

  public gridId = KeyGenerator.generate(5);
  public angularGrid: AngularGridInstance;
  public gridOptions: GridOption = {};
  public gridObj: any;
  public gridDataViewObj: any;

  private aggregationFn: Function;
  private disaggregationFn: Function;
  private useFormatter: boolean;
  private disableInput: boolean;
  private aggregatedColumns: SlickTreetableColumn[];
  private hierarchialColumns: SlickTreetableColumn[];
  private activeColumnDefinitions: SlickTreetableColumn[] = [];
  private cssGroups: any[] = [];
  private editCellMap = {
    "old_value": undefined,
    "new_value": undefined,
    "children": [],
    "field": undefined
  }


  constructor(private angularUtilService: AngularUtilService) { }

  ngOnInit() {
    this.initializeGrid();
  }


  ngOnDestroy() {
    CommonHandler.unloadScript('slicktree');
  }

  ngAfterViewInit() {
    this.activeColumnDefinitions = this.gridObj.getColumns();
    // this.applyGrouping();
    // this.updateAllTotals();
  }

  private initializeGrid() {
    CommonHandler.loadScript('slicktree', './assets/js/slick-treetable.js');
    this.prepareGridOptions();
  }

  private prepareColumnDefinitions() {
    if (this.columnDefinitions) {
      for (let col of this.columnDefinitions) {

      }
    }
  }

  private prepareGridOptions() {
    this.gridOptions = {
      enableGridMenu: false,
      editable: true,
      enableAutoResize: true,
      enableCellNavigation: false,
      enableFiltering: true,
      autoEdit: true,
      enableGrouping: true,
      enableRowSelection: false,
      enableHeaderMenu: false,
      createFooterRow: true,
      showFooterRow: false,
      footerRowHeight: 20,
      rowHeight: 25,
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: true
      },
      enableAutoTooltip: true,
      autoTooltipOptions: {
        enableForCells: true,
        enableForHeaderCells: true,
        maxToolTipLength: 50
      },
      asyncEditorLoading: false,
      createPreHeaderPanel: false,
      showPreHeaderPanel: false,
      enableAsyncPostRender: false, // for the Angular PostRenderer, don't forget to enable it
      asyncPostRenderDelay: 0,    // also make sure to remove any delay to render it,
      params: {
        angularUtilService: this.angularUtilService // provide the service to all at once (Editor, Filter, AsyncPostRender)
      }
    };
  }

  private resetCellEditMap() {
    this.editCellMap = {
      "old_value": undefined,
      "new_value": undefined,
      "children": [],
      "field": undefined
    }
  }

  public angularGridReady(angularGrid: AngularGridInstance) {
    this.angularGrid = angularGrid;
    this.gridObj = angularGrid.slickGrid;
    this.gridDataViewObj = angularGrid.dataView;
    // this.gridService = angularGrid.gridService;

    // it also exposes all the Services
    // this.angularGrid.resizerService.resizeGrid(10);
  }



}
