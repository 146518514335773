import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
//import { UserIdleService } from 'angular-user-idle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionInactivityModalComponent } from '../session-inactivity-modal/session-inactivity-modal.component';
import { ConstantHandler } from '../../handlers/constant-handler';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/auth/services/auth.service';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PrivacyStatementModalComponent } from '../privacy-statement-modal/privacy-statement-modal.component';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  constructor(
    //private userIdle: UserIdleService,
    private dialog: MatDialog,
    public globalService: GlobalService,
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

    //use global params to set these timers.
  private timeout: number = 10; //In seconds
  private idle: number = 600; //I seconds
  private subscriptions = new Subscription();

  ngOnInit() {
    if (!environment.ssoEnabled) {
      this.initialize();
    }
    if (this.globalService.globalParams.privacy_enabled && this.globalService.globalParams.privacy_enabled === "true") {
      if (!this.globalService.user.privacy_accepted && this.globalService.getLocalStorageItem('PRIVACY_ACCEPTED_UPDATED') == null) {
        const dialogRef = this.dialog.open(PrivacyStatementModalComponent, {
          disableClose: true,
          closeOnNavigation: false,
          width: '900px',
          panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
          // Logout after the inactivity.
          if (result && result.event == 'Cancel') {
            this.globalService.setLocalStorageItem('PRIVACY_ACCEPTED_UPDATED', true);
          }
        });

      }
    }
  }

  private initialize() {
    //this.userIdle.setConfigValues({ idle: this.idle, timeout: this.timeout, ping: 0 });

    //Start watching for user inactivity.
    //this.userIdle.startWatching();

    // Start watching when user idle is starting.
    //this.subscriptions.add(this.userIdle.onTimerStart().subscribe(count => this.checkForInactivity(count)));

    // Start watch when time is up.
    // this.subscriptions.add(this.userIdle.onTimeout().subscribe(() => console.log('Time is up!')));
  }

  ngOnDestroy() {
    if (!environment.ssoEnabled) {
      this.dialog.closeAll();
      //this.userIdle.stopWatching();
      //this.userIdle.stopTimer();
      this.subscriptions.unsubscribe();
    }
  }

  private checkForInactivity(count: number) {
    if (count == 1) {
      this.openSessionInactivityPopup(count);
    }
  }

  private openSessionInactivityPopup(count: number) {
    const dialogRef = this.dialog.open(SessionInactivityModalComponent, {
      disableClose: true,
      closeOnNavigation: true,
      position: ConstantHandler.commonDialogPosition,
      width: '300px',
      height: '280px',
      data: {
        timeout: this.timeout
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Logout after the inactivity.
      if (result && result.event == 'Cancel') {
        // dont do anything 
        //this.userIdle.resetTimer();
      } else {
        this.logout();
      }
    });
  }

  logout() {
    this.spinner.show();
    this.authService.logout({})
      .subscribe(
        result => {
          this.spinner.hide();
          if (result && result.response == "SUCCESS") {
            if (environment.msalEnabled) {
              this.globalService.deleteUserSession();
              this.router.navigate(['auth/msl-logout']);
            } else {
              this.router.navigate(['auth/login']);
              this.globalService.deleteUserSession();
            }
          }
        },
        error => {
          this.spinner.hide();
        });
  }

  @HostListener('window:beforeunload')
  unloadHandler(event) {
    console.log("Closing the Tab/Browser now");
    // this.authService.logout({}).subscribe();
    // this.globalService.deleteUserSession();
  }
}
