import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ParallelHasher } from 'ts-md5';
import { AlertHandler } from './alert-handler';

@Injectable()
export class InterceptorHandler implements HttpInterceptor {

    constructor(private globalService: GlobalService,
        private router: Router,
        private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.globalService.token;
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${token}`
                }
            });
        }

        if (request.body && (request.body.isFormData)) {
            delete request.body.isFormData;

            const file_checksome = getFileChecksome(request.body.file)
            request["body"]["checksum"] = file_checksome;

            const formData = new FormData();
            for (let i in request.body) {
                formData.append(i, request.body[i]);
            }
            request = request.clone({ body: formData });

        } else {
            if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
            }
        }

        //request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).catch(
            (err: HttpErrorResponse) => {
                 console.log(err)
                if (err.status === 401 && this.globalService.expiredCounter == 0) {
                    this.globalService.expiredCounter = this.globalService.expiredCounter + 1;
                    //this.authService.logout({}).subscribe();
                    AlertHandler.ErrorAlert(err.error.message+ ' :Please Login Again', 5000);
                    if (environment.ssoEnabled) {
                        if (localStorage.getItem("SSO_URL")) {
                            const ssoUrl = localStorage.getItem("SSO_URL");
                            this.globalService.deleteUserSession();
                            window.location.replace(ssoUrl);
                        }
                    } else if (environment.msalEnabled) {
                        this.globalService.deleteUserSession();
                        this.router.navigate(['auth/msl-login']);
                    } else {
                        this.globalService.deleteUserSession();
                        this.router.navigate(['auth/login']);
                    }
                }
                return throwError(err);
            }
        );
    }
}

function getFileChecksome(file){
    // CommonHandler.loadScript('slicktree', './assets/js/slick-treetable.js');
    let hasher = new ParallelHasher('./assets/js/md5_worker.js');
    const checksum = hasher.hash(file).then(function(result) {
    return result;
    });
    return checksum
}
