import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
})
export class UserGuideComponent implements OnInit {
  showTabs;
  constructor() {}

  ngOnInit() {
    this.showTabs = {
      one: true,
      two: true,
    };
  }
}
