
export class KeyGenerator {
    private static numeric = '0123456789';
    private static alphaLower = 'abcdefghijklmnopqrstuvwxyz';
    private static alphaUpper = KeyGenerator.alphaLower.toUpperCase();
    private static alphaNumeric = KeyGenerator.numeric + KeyGenerator.alphaUpper + KeyGenerator.alphaLower;

    constructor() { }

    public static generate(bits): any {
        if (!bits) {
            bits = 8;
        }
        var chars = KeyGenerator.alphaNumeric;
        var max = Math.floor(256 / chars.length) * chars.length;
        var key = "";
        while (key.length < bits) {
            var arr = KeyGenerator.randomArray(bits - key.length);
            for (var i = 0; i < arr.length; i++) {
                var x = arr[i];
                if (x < max) {
                    key += chars[x % chars.length];
                }
            }
        }
        return key;
    }

    private static randomArray(size) {
        let arr: any = [];
        for (let count = 0; count < size; count++) {
            arr.push((Math.random() * 500).toFixed(0))
        }
        return arr;
    }

}
