import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { User } from 'src/app/auth/models/user';
import { CommonHandler } from '../handlers/common-handler';
import { CookieHandler } from '../handlers/cookie-handler';
import { environment } from 'src/environments/environment';
import * as _underscore from 'underscore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class GlobalService {
  readonly COOKIE_NAME: string = 'asterisk_cookie';
  readonly roleId: string = 'Vehicle';
  readonly ACCESS_COOKIE: string = 'asterisk_access_cookie';
  public toolName: string = "Team Asterisk";
  // public headerLogo: string = "./assets/images/asterisk-header-new.png";
  public headerLogo: string = "./assets/images/asterisk-tml-header.png";
  private _user: User;
  private _token: any;
  private _globalParams: any;
  private _showTeamLogos: boolean;
  public ssoValidating: boolean = false;
  public expiredCounter: number = 0;
  public mapActiveKey: boolean = false;

  constructor(private titleService: Title, private cookieHandler: CookieHandler) {
  }
  private _buInfo: BehaviorSubject<any> = <BehaviorSubject<any>>new BehaviorSubject(null);
  private _tileInfo: BehaviorSubject<any> = <BehaviorSubject<any>>new BehaviorSubject(null);
  private _buDesc: BehaviorSubject<any> = <BehaviorSubject<any>>new BehaviorSubject(null);
  private _buSelected: BehaviorSubject<any> = <BehaviorSubject<any>>new BehaviorSubject(false);

  public buInfo: Observable<any> = this._buInfo.asObservable();
  public buDesc: Observable<any> = this._buDesc.asObservable();
  public buSelected: Observable<any> = this._buSelected.asObservable();
  public tileInfo: Observable<any> = this._tileInfo.asObservable();
  
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public createUserSession(user: User, token: any, expiresOn: any, globalParams: any): void {
    this.expiredCounter = 0;
    this._user = CommonHandler.getCopy(user);
    this._token = CommonHandler.getCopy(token);
    this._globalParams = CommonHandler.getCopy(globalParams);
    let cookie = {
      token: token,
      globalParams: globalParams
    }
    let accessCookie = {
      user: user
    }

    // this.cookieHandler.createLSCookie(this.COOKIE_NAME, JSON.stringify(cookie), expiresOn);
    // this.cookieHandler.createLSCookie(this.ACCESS_COOKIE, JSON.stringify(accessCookie), expiresOn);
    this.cookieHandler.createCookie(this.COOKIE_NAME, JSON.stringify(cookie), expiresOn);
    this.cookieHandler.createCookie(this.ACCESS_COOKIE, JSON.stringify(accessCookie), expiresOn);
  }

  public deleteUserSession(): void {
    // this.cookieHandler.deleteLSCookie(this.COOKIE_NAME);
    // this.cookieHandler.deleteLSCookie(this.ACCESS_COOKIE);
    this.cookieHandler.deleteCookie(this.COOKIE_NAME);
    this.cookieHandler.deleteCookie(this.ACCESS_COOKIE);
    this._user = undefined;
    this._token = undefined;
    this._clearStorages();
    // this._globalParams = undefined;
  }

  public get user(): User {
    if (!this._user) {
      // let cookie = this.cookieHandler.readLSCookie(this.ACCESS_COOKIE);
      let cookie = this.cookieHandler.readCookie(this.ACCESS_COOKIE);
      if (cookie) {
        this._user = JSON.parse(cookie).user;
      }
    }
    if(this.getSessionStorageItem(this.roleId)){
      this._user.role_id = JSON.parse(this.getSessionStorageItem(this.roleId)).role_id;
    }
    return this._user;
  }

  // public get token(): string {
  //   let cookie = this.cookieHandler.readLSCookie(this.COOKIE_NAME);
  //   if (cookie) {
  //     this._token = JSON.parse(cookie).token;
  //   } else {
  //     this._token = undefined;
  //   }
  //   return this._token;
  // }

  public get token(): string {
    if (!this._token) {
      let cookie = this.cookieHandler.readCookie(this.COOKIE_NAME);
      if (cookie) {
        this._token = JSON.parse(cookie).token;
      }
    }
    return this._token;
  }

  public get globalParams(): any {
    if (!this._globalParams) {
      // let cookie = this.cookieHandler.readLSCookie(this.COOKIE_NAME);
      let cookie = this.cookieHandler.readCookie(this.COOKIE_NAME);
      if (cookie) {
        this._globalParams = JSON.parse(cookie).globalParams;
      }
    }
    return this._globalParams;
  }

  public get showTeamLogos(): boolean {
    this._showTeamLogos = environment.showTeamLogos || false;
    return this._showTeamLogos;
  }

  public get authenticated(): boolean {
    return this.user != null && this.token != null;
  }

  public setSessionStorageItem(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  public deleteSessionStorageItem(key: string) {
    sessionStorage.removeItem(key);
  }

  public deleteLocalStorageItem(key: string) {
    localStorage.removeItem(key);
  }

  public getSessionStorageItem(key: string): any {
    return sessionStorage.getItem(key);
  }

  private _clearStorages(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  public getLocalStorageItem(key: string): any {
    return localStorage.getItem(key);
  }

  public setLocalStorageItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  public setToolIcon(route: string) {
    let role = _underscore.findWhere(this.user.roles, { role_privilege_route: route })
    this.setTitle(role.label);
    this.toolName = role.label;
    this.headerLogo = role.image_path;
  }

  public hasRole(userRole: String): boolean {
    const role_index = this.user.roles.findIndex(role => role.role_name.toLowerCase() === userRole.toLowerCase());
    if (role_index > -1) {
      return true;
    }
    return false;
  }

  public create_UUID() {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16); return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public updateBUInfo(data: any): void {
    this._buInfo.next( data );
  }

  public updateTileInfo(data: any): void {
    this._tileInfo.next( data );
  }

  public updateBUDesc(data: any): void {
    this._buDesc.next( data );
  }

  public updateBUselect(data: any): void {
    this._buSelected.next( data );
  }
}
