import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ng-dropdown',
  templateUrl: './ng-dropdown.component.html',
  styleUrls: ['./ng-dropdown.component.scss']
})
export class NgDropdownComponent implements OnInit {
  @Input() label: string;
  @Input() width: string = "100px";
  @Input() optionLabel: string;
  @Input() placeholder: string = "Choose";
  @Input() options: Array<any> = new Array<any>();
  @Input() filter: boolean = false;
  @Input() disabled: boolean = false;
  @Input() appendToBody: boolean = true;
  @Output() onSelect = new EventEmitter<any>();

  @Input() selectedOption: any;

  constructor() {
  }

  ngOnInit() {
    // console.log(this.optionLabel)
    // this.options = [
    //   { name: 'New York', code: 'NY' },
    //   { name: 'Rome', code: 'RM' },
    //   { name: 'London', code: 'LDN' },
    //   { name: 'Istanbul', code: 'IST' },
    //   { name: 'Paris', code: 'PRS' }
    // ];
    // this.optionLabel = "name";
  }

  public onValueChange(event: any) {
    this.onSelect.emit(event.value);
  }
}
