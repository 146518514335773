import { Injectable } from '@angular/core';
import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class SocketService {

  private socket: any;

  constructor() {
  }

  public initSocket(): void {
    if (!this.socket) {
      this.socket = socketIo.io(environment.socketConfig.url, environment.socketConfig.options);
    }
  }

  public removeSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.emit('client_disconnected');

      this.socket = undefined;
    }
  }

  registerClient(data: any) {
    console.log('client connecting')
    this.socket.emit('client_connected', data);
  }

  getProgress(data: any) {
    console.log('get Progress',data)
    this.socket.emit('get_progress', data);
  }

  getSocketServerResponse() {
    return new Observable<any>(observer => {
      this.socket.on('server_response', (data: any) => observer.next(data));
    });
  }

  getSocketProgressResponse() {
    return new Observable<any>(observer => {
      this.socket.on('progress_response', (data: any) => observer.next(data));
    });
  }
}
