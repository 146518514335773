export class MessageHandler {
    public static INTERNAL_ERROR = 'We are unable to serve your request at this time.';
    public static ERROR_ACCOUNT_VERIFICATION = 'Sorry, we are not able to verify your account. Please try later.';
    public static INFO_DATA_SAVED = 'Data Saved Successfully.';
  public static SCENARIO_ADDED = 'Scenario Added Successfully.';
  public static SCENARIO_UPDATED = 'Scenario Updated Successfully.';
    public static INFO_FORECAST_EXECUTION_SUBMITTED = 'Forecast Execution Request Submitted Successfully.';
    public static FILE_UPLOADED_SUCCESSFULLY = 'File Uploaded Successfully';
    public static FILE_SAVED_SUCCESSFULLY = 'File Saved Successfully to Database';
    public static CALCULATE_SUCCESSFULLY = 'Calculation Done Successfully';
    public static WARNING_NO_DATA_TO_SAVE = 'No data to save.';
    public static WARNING_DATA_TRUNCATE = 'Data will be truncated before uploading.';
    public static WARNING_SINGLE_HIER_COL = 'Minimum one hierarchy column is required.';
    public static WARNING_SINGLE_AGG_COL = 'Minimum one aggregated column is required.';
    public static WARNING_BOTH_PASSWORD_REQUIRED = 'To change password, both current and new passwords are required.';
    public static WARNING_BOTH_PASSWORD_SAME = 'Current Password and New Password cannot be same.';
    public static WARNING_FILE_COLUMNS_NOT_MATCHED = 'Uploaded File columns not matched with Database columns.';
    public static WARNING_FILE_HAS_EMPTY_ROWS = 'Uploaded File contains empty rows. Please upload valid file.';
    public static WARNING_INVALID_CSV_FILE = 'Please import valid .csv file(s).';
    public static WARNING_SECURITY_ANSWER_MISSING = 'Please provide valid answer.';
    public static ERROR_INVALID_FILE_DATA = 'File Data is not valid.';
    public static ERROR_INVALID_ITERATION_DATA = 'Please check the current iteration configuration.';
    public static ERROR_FIRST_SELECT_CYCLE = 'Please check selection of cycle.';
    public static SELECT_AT_LEAST_ONE_CONFIG = "Please select atleast one config."
    public static WARNING_MAX_CYCLES_SELECTED = "Maximum 5 cycles can be selected."

    public static ERROR_IN_SKU_GROWTH = 'Error in Sku iterartion';
    public static DELETE_SUCCESS = 'Deleted Successfully';
    public static GENERATE_SUCCESS = 'Plan generated successfully';
}
