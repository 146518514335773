import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthGuard } from './guards/auth.guard';
import {DirtyCheckGuard} from "./guards/dirty-check.guard";
import { GlobalService } from './services/global.service';
import { LandingComponent } from './components/landing/landing.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieHandler } from './handlers/cookie-handler';
import { InterceptorHandler } from './handlers/interceptor-handler';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './components/main/main.component';
import { RouterModule } from '@angular/router';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AuthService } from '../auth/services/auth.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { ChangeSecurityQAModalComponent } from './components/change-security-qa-modal/change-security-qa-modal.component';
import { StaticLandingPageComponent } from './components/static-landing-page/static-landing-page.component';
//import { UserIdleModule } from 'angular-user-idle';
import { SessionInactivityModalComponent } from './components/session-inactivity-modal/session-inactivity-modal.component';
import { ConcurrentUserSessionModalComponent } from './components/concurrent-user-session-modal/concurrent-user-session-modal.component';
import { PrivacyStatementModalComponent } from './components/privacy-statement-modal/privacy-statement-modal.component';
import { PrivacyStatementPageComponent } from './components/privacy-statement-page/privacy-statement-page.component';
import { VideoLibraryComponent } from './components/user-guide/video-library/video-library.component';
import { DocumentLibraryComponent } from './components/user-guide/document-library/document-library.component';
import { TabViewModule } from 'primeng/primeng';
import { UserGuideComponent } from './components/user-guide/user-guide.component';
import { VehicleSelectComponent } from './components/vehicle-select/vehicle-select.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    MainComponent,
    UserProfileComponent,
    ChangePasswordModalComponent,
    ChangeSecurityQAModalComponent,
    StaticLandingPageComponent,
    SessionInactivityModalComponent,
    ConcurrentUserSessionModalComponent,
    PrivacyStatementModalComponent,
    PrivacyStatementPageComponent,
    UserGuideComponent,
    VideoLibraryComponent,
    DocumentLibraryComponent,
    VehicleSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    RouterModule,
    NgxSpinnerModule,
    TabViewModule
    //UserIdleModule.forRoot({})
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    MainComponent,
    LandingComponent,
    UserProfileComponent,
    HttpClientModule,
    NgxSpinnerModule,
    //UserIdleModule
  ],
  providers: [
    AuthGuard,
    DirtyCheckGuard,
    GlobalService,
    AuthService,
    CookieHandler,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorHandler, multi: true }
  ],
  entryComponents: [
    ChangePasswordModalComponent,
    ChangeSecurityQAModalComponent,
    SessionInactivityModalComponent,
    ConcurrentUserSessionModalComponent,
    PrivacyStatementModalComponent
  ]
})
export class CoreModule { }
