import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'amountConverter'
})
export class AmountConverterPipe implements PipeTransform {
    transform(value: number | string, locale?: string, fractionDigits?: number): string {
        return new Intl.NumberFormat(locale, {
            maximumFractionDigits: fractionDigits || 0
        }).format(Number(value));
    }
}