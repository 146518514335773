import { Injectable } from '@angular/core';
import {CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {HasUnsavedData} from "../dirty-check/has-unsaved-data";
import {NormalTableComponent} from "../../tools/cockpit/components/grids/normal-table/normal-table.component";
import {GlobalService} from "../services/global.service";
import {CpHeaderComponent} from "../../tools/cockpit/components/cp-screens/cp-header/cp-header.component";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}



@Injectable()
export class DirtyCheckGuard implements CanDeactivate<ComponentCanDeactivate> {
  public scenario:any;
  constructor(private globalService: GlobalService , private router:Router) {
  }
  canDeactivate(target: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    /* return target.canDeactivate()?
       true :
       confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');

   }*/
    if(target.canDeactivate()){
      console.log("deactivate");
      return true;
    } else {
      if (confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.')) {
        console.log("ok");
        this.scenario= JSON.parse(this.globalService.getLocalStorageItem('scenario'));
        this.scenario.selectedStep=this.scenario.nextSelectedStep;
        this.scenario.hasChanges =false;
        this.globalService.setLocalStorageItem("scenario", JSON.stringify(this.scenario));

        return true;
      } else {
        this.scenario= JSON.parse(this.globalService.getLocalStorageItem('scenario'));
        this.scenario.selectedStep =this.scenario.lastSelectedStep;
        this.globalService.setLocalStorageItem("scenario", JSON.stringify(this.scenario));
        return false;
      }
    }
  }
}



