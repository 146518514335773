import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private globalService: GlobalService, private route: ActivatedRoute,) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Authenticated
    if (this.globalService.authenticated) {

      // SSO (Added New Code)
      if (environment.ssoEnabled) {
        if ((state.url.includes('auth') && !state.url.includes('auth/sso-login')) || state.url.includes('app/landing') || state.url.includes('app/vehicle')) {
          if (sessionStorage.getItem("sso_return_url")) {
            this.router.navigate([sessionStorage.getItem("sso_return_url")]);
          } else {
            this.router.navigate(['auth/sso-login']);
          }
        } else if (!state.url.includes('auth/sso-login')) {
          const role_privilege = route.data.role_privilege;
          const roles = this.globalService.user.roles;
          if (role_privilege && roles) {
            const role_index = roles.findIndex(role => role.role_privilege.toLowerCase() === role_privilege.toLowerCase());
            if (role_index === -1) {
              this.router.navigate(['auth/sso-login']);
            }
          }
        }

        return true;
      }


      else if (environment.msalEnabled) {
        if (state.url.includes('auth')) {
          this.router.navigate(['app']);
        } else {
          const role_privilege = route.data.role_privilege;
          const roles = this.globalService.user.roles;
          if (role_privilege && roles) {
            const role_index = roles.findIndex(role => role.role_privilege.toLowerCase() === role_privilege.toLowerCase());
            if (role_index === -1) {
              this.router.navigate(['app']);
            }
          }
        }
        return true;
      }

      // Non SSO
      else {
        if (state.url.includes('auth')) {
          this.router.navigate(['app']);
        } else {
          const role_privilege = route.data.role_privilege;
          const roles = this.globalService.user.roles;
          if (role_privilege && roles) {
            const role_index = roles.findIndex(role => role.role_privilege.toLowerCase() === role_privilege.toLowerCase());
            if (role_index === -1) {
              this.router.navigate(['app']);
            }
          }
        }

        return true;
      }

    }


    // Unauthenticated
    else {
      // SRM Login
      if (environment.srmLogin) {
          if (state.url.includes('auth/srm-login')) {
            return true;
          }
          this.router.navigate(['auth/srm-login']);
          return false;
        }
      // SSO
      if (environment.ssoEnabled) {
        if (state.url.includes('auth/sso-login')) {
          return true;
        }
        this.router.navigate(['auth/sso-login']);
        return false;
      }

      else if (environment.msalEnabled) {
        if (state.url.includes('auth/msl-logout')) {
          return true;
        }
        if (state.url.includes('auth/msl-login')) {
          return true;
        }
        let uuid = this.globalService.create_UUID();
        window.localStorage.setItem("state", uuid)
        var redirectUrl = `https://login.microsoftonline.com/${environment.msalTenant}/oauth2/v2.0/authorize?
        client_id=${environment.msalClient}
        &response_type=code
        &redirect_uri=${environment.msalRedirectUrl}%2Fauth%2Fmsl-login
        &response_mode=query
        &scope=openid%20offline_access%20user.read
        &state=${uuid}`;
        setTimeout(function () {
          location.replace(redirectUrl);
        }, 0);
        return false;
      }

      // Non SSO
      else {
        if (state.url.includes('/auth/login') || state.url.includes('/auth/forgot-password')) {
          return true;
        }
        this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }

  }
}
