
export let ConstantHandler = {   
    months: [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 }

    ],    
    years: (): any => {
        let yrs = [];
        let start = 2018;
        let end = new Date().getFullYear();
        for (let count = start; count <= end; count++) {
            yrs.push(count);
        }
        return yrs;
    },

    currentMonth: new Date().getMonth() + 1,
    currentYear: new Date().getFullYear(),   

    deleteConfirmDialogDimension: {
        WIDTH: "350px",
        HEIGHT: "auto"
    },
  
    commonDialogDimension: {
        WIDTH: "350px",
        HEIGHT: "auto"
    },

    commonDialogPosition: {
        top : "70px"
    }
};

export const NodePriority = {
    "National": 1,
    "Depot": 2,
};

export const SKUPriority = {
    "Division": 1,
    "Category": 2,
    "Brand": 3,
    "Product": 4,
    "SKU Code": 5,
};

export const MonthPriority = {
    "Month": 1,
    "Week": 2,
    "Day": 3,
};

export const skuList = ["Division", "Category", "Brand", "Product", "SKU Code"];
export const nodeList = ["National", "Depot"];
export const timeList = ["Month", "Week", "Day"];
