import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  public ssoEnabled = environment.ssoEnabled;
  public msalEnabled = environment.msalEnabled;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public navigateToHome() {
    this.router.navigate(['/']);
  }

}
