import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/auth/models/user';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  
  public passwordType: any = 'password';
  public newPasswordType: any = 'password';
  public user: User;

  constructor(public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.user = this.data.user;
  }

  public togglePassword() {
    this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
  }

  public toggleNewPassword() {
    this.newPasswordType = this.newPasswordType == 'password' ? 'text' : 'password';
  }

  onSaveClick() {
    let data = {
      user: this.user
    }
    this.dialogRef.close(data);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

}
