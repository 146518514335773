import {ComponentCanDeactivate} from './component-can-deactivate';
import {NgForm} from "@angular/forms";
import { Directive } from "@angular/core";

@Directive()
export abstract class FormCanDeactivate extends ComponentCanDeactivate{

 abstract get form():NgForm; //Case where Form is used.
 abstract get saved(): boolean; //Case where Form is not used.

 canDeactivate():boolean{
   if(this.form){
    return this.form.submitted || !this.form.dirty
   }else{
    return (this.saved)
   }
  }
}
