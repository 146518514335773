import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieHandler } from '../../handlers/cookie-handler';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-vehicle-select',
  templateUrl: './vehicle-select.component.html',
  styleUrls: ['./vehicle-select.component.scss']
})
export class VehicleSelectComponent implements OnInit {

  public vehicles: Array<any> = new Array<any>();
  constructor(  private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private cookieHandler: CookieHandler) { }
  
    ngOnInit(): void {
      this.vehicles = this.globalService.user.bu;
    }
  
    public selectTool(route: String) {
      this.globalService.setSessionStorageItem( "Vehicle", JSON.stringify(route));
      this.globalService.user.role_id = route["role_id"];
      this.globalService.updateBUInfo(route["bu_name"]);
      this.globalService.updateBUDesc(route["bu_description"]);
      this.globalService.updateTileInfo(" ");
      this.globalService.deleteSessionStorageItem( "tileInfo");
      let path = route['bu_route'];
      this.router.navigate([`../${path}`], { relativeTo: this.route });
    }
  }