import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { User } from 'src/app/auth/models/user';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AlertHandler } from '../../handlers/alert-handler';
import { MessageHandler } from '../../handlers/message-handler';
import { FormCanDeactivate } from '../../dirty-check/form-can-deactivate';
import { MatDialog } from '@angular/material/dialog';
import { ConstantHandler } from '../../handlers/constant-handler';
import { ChangeSecurityQAModalComponent } from '../change-security-qa-modal/change-security-qa-modal.component';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends FormCanDeactivate implements OnInit {
  @ViewChild('form')
  @ViewChild('fullname') nameField: ElementRef;
  @ViewChild('email') emailField: ElementRef;

  form: any;
  public saved: boolean = true;
  public loading: boolean = false;
  public editemail: boolean = false;
  public editname: boolean = false;
  public editmobile: boolean = false;
  public changePassword: boolean = false;
  public saveActive: boolean = false;

  public user: User;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private globalService: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.globalService.setTitle('User Profile');
    this.fetchUserDetails();
  }

  private fetchUserDetails() {
    this.authService.fetchUser()
      .subscribe(
        result => {
          if (result && result.response == "SUCCESS") {
            this.user = result.user;
            this.user['image'] = result.user['profile_pic'] ? "data:image/png;base64" + result.user['profile_pic'] : './assets/images/user_login.png';
          } else {
            AlertHandler.ErrorAlert(result.message);
          }

          this.form = this.formBuilder.group({
            fullname: [this.user.fullname, Validators.required],
            email: [this.user.email, Validators.required]
          });
          this.form.valueChanges.subscribe(data => console.log('form changes', data));
          this.loading = false;
        },
        error => {
          AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
          this.loading = false;
        });
  }

  public saveUserDetails() {
    this.saved = false;
    // Validate Password Fields
    let passwordError = this.validatePasswords();
    if (passwordError) {
      AlertHandler.WarningAlert(passwordError);
      return;
    }

    let user = {
      fullname: this.user.fullname,
      email: this.user.email,
      password: this.user.password || null,
      new_password: this.user.new_password || null,
      security_question_id: this.user.security_question_id || null,
      security_answer: this.user.security_answer || null,
      profile_pic: this.user.profile_pic || null
    }
    this.authService.updateUser(user)
      .subscribe(
        result => {
          if (result && result.response == "SUCCESS") {
            AlertHandler.SuccessAlert(MessageHandler.INFO_DATA_SAVED);
          } else {
            AlertHandler.ErrorAlert(result.message);
          }
          this.saved = true;
          this.saveActive = false;
        },
        error => {
          AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
          this.saved = true;
        })
  }

  private validatePasswords(): string {
    this.user.password = this.user.password ? this.user.password.trim() : undefined;
    this.user.password = this.user.password != '' ? this.user.password : undefined;
    this.user.new_password = this.user.new_password ? this.user.new_password.trim() : undefined;
    this.user.new_password = this.user.new_password != '' ? this.user.new_password : undefined;

    if ((this.user.password && !this.user.new_password) || (!this.user.password && this.user.new_password)) {
      return MessageHandler.WARNING_BOTH_PASSWORD_REQUIRED;
    }

    if (this.user.password && this.user.new_password &&
      this.user.password == this.user.new_password) {
      return MessageHandler.WARNING_BOTH_PASSWORD_SAME;
    }
    return undefined;
  }

  public openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
      disableClose: false,
      closeOnNavigation: true,
      position: ConstantHandler.commonDialogPosition,
      width: '500px',
      height: ConstantHandler.commonDialogDimension.HEIGHT,
      data: {
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user.password = result.user.password;
        this.user.new_password = result.user.new_password;
        this.validatePasswords();
        this.saveUserDetails();
        this.router.navigate(['auth/login']);
        this.globalService.deleteUserSession();
      }
    });
  }

  public openChangeSecurityQADialog() {
    console.log(this.user);
    let data = { user: this.user };

    if (this.user.security_question_id) {
      data['selectedQuestionId'] = this.user.security_question_id;
    }

    const dialogRef = this.dialog.open(ChangeSecurityQAModalComponent, {
      disableClose: false,
      closeOnNavigation: true,
      position: ConstantHandler.commonDialogPosition,
      width: '500px',
      height: ConstantHandler.commonDialogDimension.HEIGHT,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.user.security_question_id = result.user.security_question_id;
        this.user.security_answer = result.user.security_answer;
        this.saveUserDetails();
      }
    });
  }

  public editField(ele) {
    if (ele === 'fullname') {
      setTimeout(() => {
        this.nameField.nativeElement.focus();
      }, 0);
    } else if (ele === 'email') {
      setTimeout(() => {
        this.emailField.nativeElement.focus();
      }, 0);
    }
  }

  public setField(ele) {
    let initUser = this.globalService.user;
    if (ele === 'fullname') {
      if (initUser.fullname !== this.user.fullname) {
        this.saveActive = true;
      } else {
        this.saveActive = false;
      }
    } else if (ele === 'email') {
      if (initUser.email !== this.user.email) {
        this.saveActive = true;
      } else {
        this.saveActive = false;
      }
    }
    else if (ele === 'profile_pic') {
      if (initUser.profile_pic !== this.user.profile_pic) {
        this.saveActive = true;
      } else {
        this.saveActive = false;
      }
    }
  }
}
