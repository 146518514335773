import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../core/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBiEmbedService {

  constructor(private http: HttpClient, private globalService: GlobalService) { }

  public getDetails = () => {
    let data = {"role_id" : this.globalService.user.roles[0].role_id};
    const url = `${environment.serverUrl}/auth/fetch-pbi-embed-details`;
    return this.http.post(url, data);
  }
}
