import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ng-multi-select',
  templateUrl: './ng-multi-select.component.html',
  styleUrls: ['./ng-multi-select.component.scss']
})
export class NgMultiSelectComponent implements OnInit {
  @Input() label: string;
  @Input() width: string = "100px";
  @Input() optionLabel: string;
  @Input() options: Array<any> = new Array<any>();
  @Input() disabled: boolean = false;
  @Input() selectedOptions: Array<any>;
  @Input() selectionLimit: number;
  @Output() onSelect = new EventEmitter<any>();



  constructor() {
  }

  ngOnInit() {
  }

  public onValueChange(event: any) {
    this.onSelect.emit(event.value);
  }
}
