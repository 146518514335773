import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingComponent } from './core/components/landing/landing.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MainComponent } from './core/components/main/main.component';
import { UserProfileComponent } from './core/components/user-profile/user-profile.component';
import { StaticLandingPageComponent } from './core/components/static-landing-page/static-landing-page.component';
import { PrivacyStatementPageComponent } from './core/components/privacy-statement-page/privacy-statement-page.component';
import { UserGuideComponent } from './core/components/user-guide/user-guide.component';
import { VideoLibraryComponent } from './core/components/user-guide/video-library/video-library.component';
import { VehicleSelectComponent } from './core/components/vehicle-select/vehicle-select.component';

const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule), canActivate: [AuthGuard] },

  {
    path: 'app', component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'vehicle',
        pathMatch: 'full'
      },
      { path: 'vehicle', component: VehicleSelectComponent, canActivate: [AuthGuard] },
      {
        path: 'landing',
        component: LandingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'dpt',
        loadChildren: () => import('src/app/tools/dpt/dpt.module').then(m => m.DptModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'dpt' }
      },
      {
        path: 'dpt-store',
        loadChildren: () => import('src/app/tools/dpt-store/dpt-store.module').then(m => m.DptStoreModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'dpt-store' }
      },
      {
        path: 'promo',
        loadChildren: () => import('src/app/tools/promo/promo.module').then(m => m.PromoModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'promo' }
      },
      {
        path: 'stat-analysis',
        loadChildren: () => import('src/app/tools/stat-analysis/stat-analysis.module').then(m => m.StatAnalysisModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'stat-analysis' }
      },
      {
        path: 'ml-stat-analysis',
        loadChildren: () => import('src/app/tools/ml-stat-analysis/ml-stat-analysis.module').then(m => m.MlStatAnalysisModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'ml-stat-analysis' }
      },
      {
        path: 'stat-execution',
        loadChildren: () => import('src/app/tools/stat-execution/stat-execution.module').then(m => m.StatExecutionModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'stat-execution' }
      },
      {
        path: 'ml-stat-forecast-execution',
        loadChildren: () => import('src/app/tools/ml-stat-forecast-execution/ml-stat-forecast-execution.module').then(m => m.MlStatForecastExecutionModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'ml-stat-forecast-execution' }
      },
      {
        path: 'config',
        loadChildren: () => import('src/app/tools/config/config.module').then(m => m.ConfigModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'config' }
      },
      {
        path: 'classifier',
        loadChildren: () => import('src/app/tools/classifier/classifier.module').then(m => m.ClassifierModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'classifier' }
      },
      {
        path: 'ogt',
        loadChildren: () => import('src/app/tools/ogt/ogt.module').then(m => m.OgtModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'ogt' }
      },
      {
        path: 'srt',
        loadChildren: () => import('src/app/tools/srt/srt.module').then(m => m.SrtModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'srt' }
      },
      {
        path: 'inventory',
        loadChildren: () => import('src/app/tools/inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'inventory' }
      },
      {
        path: 'indent',
        loadChildren: () => import('src/app/tools/indent/indent.module').then(m => m.IndentModule),
        //loadChildren: 'src/app/tools/ipt/ipt.module#IptModule',
        canActivate: [AuthGuard],
        data: { role_privilege: 'indent' }
      },
      {
        path: 'rccp',
        loadChildren: () => import('src/app/tools/rccp/rccp.module').then(m => m.RccpModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'rccp' }
      },
      {
        path: 'dbr',
        loadChildren: () => import('src/app/tools/db-rep/db-rep.module').then(m => m.DbRepModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'dbr' }
      },
      {
        path: 'ipt',
        loadChildren: () => import('src/app/tools/ipt/ipt.module').then(m => m.IptModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'ipt' }
      },
      {
        path: 'scp',
        loadChildren: () => import('src/app/tools/scp/scp.module').then(m => m.ScpModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'scp' }
      },
      {
        path: 'dlp',
        loadChildren: () => import('src/app/tools/daylevel/daylevel.module').then(m => m.DaylevelModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'dlp' }
      },
      {
        path: 'mto',
        loadChildren: () => import('src/app/tools/mto/mto.module').then(m => m.MtoModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'mto' }
      },
      {
        path: 'sdp',
        loadChildren: () => import('src/app/tools/sdp/sdp.module').then(m => m.SdpModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'sdp' }
      },
      {
        path: 'my-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'privacy',
        component: PrivacyStatementPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user-guide',
        component: UserGuideComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tools-landing',
        component: StaticLandingPageComponent
      },
      { path: 'covid', loadChildren: () => import('src/app/tools/covid19/covid19.module').then(m => m.Covid19Module), canActivate: [AuthGuard], data: { role_privilege: 'covid19' } },
      { path: 'ltp', loadChildren: () => import('src/app/tools/ltp/ltp.module').then(m => m.LtpModule), canActivate: [AuthGuard], data: { role_privilege: 'ltp' } },
      { path: 'sp', loadChildren: () => import('src/app/tools/sp/sp.module').then(m => m.SpModule), canActivate: [AuthGuard], data: { role_privilege: 'dupont-poc' } },
      { path: 'sp-config', loadChildren: () => import('src/app/tools/sp-config/sp-config.module').then(m => m.SpConfigModule), canActivate: [AuthGuard], data: { role_privilege: 'sp-config' } },
      {
        path: 'covid',
        loadChildren: () => import('src/app/tools/covid19/covid19.module').then(m => m.Covid19Module),
        canActivate: [AuthGuard],
        data: { role_privilege: 'covid19' }
      },
      {
        path: 'ltp',
        loadChildren: () => import('src/app/tools/ltp/ltp.module').then(m => m.LtpModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'ltp' }
      },
      {
        path: 'sp',
        loadChildren: () => import('src/app/tools/sp/sp.module').then(m => m.SpModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'sp' }
      },
      {
        path: 'cockpit',
        loadChildren: () => import('src/app/tools/cockpit/cockpit.module').then(m => m.CockpitModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'cockpit' }
      },
      {
        path: 'cp-config',
        loadChildren: () => import('src/app/tools/cockpit-config/cockpit-config.module').then(m => m.CockpitConfigModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'cp-config' }
      },
      {
        path: 'sp-config',
        loadChildren: () => import('src/app/tools/sp-config/sp-config.module').then(m => m.SpConfigModule),
        canActivate: [AuthGuard],
        data: { role_privilege: 'sp-config' }
      },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
