import { trigger, state, style, animate, transition } from '@angular/animations';

export class CommonHandler {

  constructor() { }

  public static fadeAnimation: any = trigger('fadeAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.5s', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate('0.5s', style({ opacity: 0 }))
    ])
  ]);

  public static getCopy(object: any): any {
    if (object) {
      return JSON.parse(JSON.stringify(object));
    }
    return undefined;
  }

  public static async wait(ms: number = 3000) {
    await new Promise(resolve => setTimeout(resolve, ms));
  }

  public static loadScript(id: string, src: string, tag: string = 'head') {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'utf-8';
    document.getElementsByTagName(tag)[0].appendChild(script);
  }

  public static unloadScript(id: string) {
    let script = document.getElementById(id);
    if (script) {
      script.remove();
    }
  }

  public static getMonths(): Array<any> {
    let months = [
      { field: 'January', header: 'Jan' },
      { field: 'February', header: 'Feb' },
      { field: 'March', header: 'Mar' },
      { field: 'April', header: 'Apr' },
      { field: 'May', header: 'May' },
      { field: 'June', header: 'Jun' },
      { field: 'July', header: 'Jul' },
      { field: 'August', header: 'Aug' },
      { field: 'September', header: 'Sep' },
      { field: 'October', header: 'Oct' },
      { field: 'November', header: 'Nov' },
      { field: 'December', header: 'Dec' }
    ];
    return months;
  }

  public static getCycleEndingSalesMonth() {
    const months = {
      Jan: 12,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11
    };
    return months;
  }

  public static isEmpty(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  public static getMonthYearObject(monthArr: string[], currMonth: string, year: number) {
    // To avoid reference issues
    monthArr = JSON.parse(JSON.stringify(monthArr));
    let index = monthArr.indexOf('December'),
      i: number = 0;
    let returnArray: any[] = [];

    if (index !== -1) {
      let currMonthIndex = monthArr.indexOf(currMonth);
      if (currMonthIndex > index || currMonthIndex === -1) {
        for (i = 0; i <= index; i++) {
          let y = year - 1
          returnArray.push({ month: monthArr[i], currYear: y, prevYear: y - 1 });
        }
        if (index < (monthArr.length - 1)) {
          for (i = index + 1; i < monthArr.length; i++) {
            returnArray.push({ month: monthArr[i], currYear: year, prevYear: year - 1 });
          }
        }
      } else {
        for (i = 0; i <= index; i++) {
          returnArray.push({ month: monthArr[i], currYear: year, prevYear: year - 1 });
        }
        if (index < (monthArr.length - 1)) {
          for (i = index + 1; i < monthArr.length; i++) {
            returnArray.push({ month: monthArr[i], currYear: year + 1, prevYear: year });
          }
        }
      }
    } else {
      for (i = 0; i < monthArr.length; i++) {
        returnArray.push({ month: monthArr[i], currYear: year, prevYear: year - 1 });
      }
    }
    return returnArray;
  }

  public static getCurrentYear(): number {
    return new Date().getFullYear()
  }

  public static getCurrentMonthNumber(): number {
    return new Date().getMonth() + 1
  }

  public static getCurrentMonthName(): string {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[new Date().getMonth()]
  }

  public static getCurrentMonthShortName(): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sepr", "Oct", "Nov", "Dec"
    ];
    return monthNames[new Date().getMonth()]
  }

  public static getMonthNumber(m: string): number {
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months.findIndex(month => month == m) + 1
  }

  public static getMonthName(monthNumber: number): string {
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber - 1]
  }

  public static readUploadedFileAsText = (inputFile: any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };
  public static getDaysOfMonth(){
    const days =
    [
      { field: 'January', header: '31' },
      { field: 'February', header: '28' },
      { field: 'March', header: '31' },
      { field: 'April', header: '30' },
      { field: 'May', header: '31' },
      { field: 'June', header: '30' },
      { field: 'July', header: '31' },
      { field: 'August', header: '31' },
      { field: 'September', header: '30' },
      { field: 'October', header: '31' },
      { field: 'November', header: '30' },
      { field: 'December', header: '31' }
    ];
    return days ;
  }

  public static getYYYYMMDD(d: any) {
    let yyyy = d.getFullYear().toString();
    let mm = (d.getMonth() + 101).toString().slice(-2);
    let dd = (d.getDate() + 100).toString().slice(-2);
    return `${yyyy}-${mm}-${dd}`;
  }

  
  public static getMMDDYYYY(d: any) {
    let yyyy = d.getFullYear().toString();
    let mm = (d.getMonth() + 101).toString().slice(-2);
    let dd = (d.getDate() + 100).toString().slice(-2);
    return `${mm}/${dd}/${yyyy}`;
  }
}
