import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { AlertHandler } from 'src/app/core/handlers/alert-handler';
import { MessageHandler } from 'src/app/core/handlers/message-handler';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Credential } from 'src/app/auth/models/credential';


@Component({
  selector: 'app-concurrent-user-session-modal',
  templateUrl: './concurrent-user-session-modal.component.html',
  styleUrls: ['./concurrent-user-session-modal.component.scss']
})
export class ConcurrentUserSessionModalComponent implements OnInit {

  public credential: Credential = new Credential();
  private returnUrl: string;
  private loginCurrentSystem: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.credential = this.data.credential;
    this.returnUrl = this.data.returnUrl;
  }

  onCancel() {
    this.loginCurrentSystem = false;
  }

  allowCurrentLogin() {
    console.log("Allow current login!!");
    this.loginCurrentSystem = true;
    this.spinner.show();
    this.credential.loginCurrentSystem = this.loginCurrentSystem;
    if (environment.msalEnabled) {
      let uuid = this.route.snapshot.queryParamMap.get('state');
      let code = this.route.snapshot.queryParamMap.get('code');
      if (localStorage.getItem("state") === uuid) {
        this.authService.mslLogin(code, this.loginCurrentSystem)
          .subscribe(result => {
            if (result && result.response == "SUCCESS") {
              this.globalService.deleteUserSession()
              this.globalService.createUserSession(result.user, result.token, result.expires_on, result.global_params);
              localStorage.setItem("username", result.user["username"]);
              localStorage.setItem("state", uuid);
              this.router.navigate([this.returnUrl])
              setTimeout(() => { this.spinner.hide(); }, 1000);
            } else {
              // Display Error
              AlertHandler.ErrorAlert(result.message);
              this.globalService.deleteUserSession()
              this.spinner.hide();
            }
          }, error => {
            console.log(error);
            AlertHandler.ErrorAlert(error.message);
            this.spinner.hide();
          });
      } else {
        AlertHandler.ErrorAlert('Unauthorized: Please login with an authorized user');
        this.spinner.hide();
      }
    } else {
      this.authService.login(this.credential)
        .subscribe(
          result => {
            this.spinner.hide();
            if (result && result.response == "SUCCESS") {
              this.globalService.createUserSession(result.user, result.token, result.expires_on, result.global_params);
              localStorage.setItem("username", result.user["username"]);
              this.router.navigate([this.returnUrl]);
              this.loginCurrentSystem = false;
            } else {
              AlertHandler.ErrorAlert(result.message);
              this.loginCurrentSystem = false;
            }
          },
          error => {
            this.spinner.hide();
            AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
          });
    }
  }

}
