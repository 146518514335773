import swal from 'sweetalert2';

export class AlertHandler {

  constructor() { }

  public static SuccessAlert(title: any, ms?: number, customClass?: string) {
    swal.fire({
      type: 'success',
      title: title,
      showConfirmButton: true,
      //timer: ms || 2500,
      customClass: customClass || 'swal-wide-sm'
    });
  }

  public static InfoAlert(title: any, ms?: number, customClass?: string) {
    swal.fire({
      type: 'info',
      title: title,
      showConfirmButton: false,
      timer: ms || 2500,
      customClass: customClass || 'swal-wide-sm'
    });
  }

  public static WarningAlert(title: any, ms?: number, customClass?: string, enableTimer: boolean = true) {
    if (enableTimer) {
      swal.fire({
        type: 'warning',
        title: title,
        showConfirmButton: false,
        timer: ms || 2500,
        customClass: customClass || 'swal-wide-sm'
      });
    } else {
      swal.fire({
        type: 'warning',
        title,
        showConfirmButton: true,
        customClass: customClass || 'swal-wide-sm'
      });
    }
  }

  public static ErrorAlert(title: any, ms?: number, customClass?: string, enableTimer: boolean = true) {
    if (enableTimer) {
      swal.fire({
        type: 'error',
        title: title,
        showConfirmButton: false,
        timer: ms || 2500,
        customClass: customClass || 'swal-wide-sm'
      });
    } else {
      swal.fire({
        type: 'error',
        title,
        showConfirmButton: true,
        customClass: customClass || 'swal-wide-sm'
      });
    }
  }


  public static DetailedErrorAlert(title: any, customClass?: string) {
    swal.fire({
      type: 'error',
      title: title,
      showConfirmButton: true,
      customClass: customClass || 'swal-wide-sm'
    });
  }

  public static DetailedWarningAlert(title: any, customClass?: string) {
    swal.fire({
      type: 'warning',
      title: title,
      showConfirmButton: true,
      customClass: customClass || 'swal-wide-sm'
    });
  }




}
