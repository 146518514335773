import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MessageHandler } from '../../handlers/message-handler';
import { AlertHandler } from '../../handlers/alert-handler';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-change-security-qa-modal',
  templateUrl: './change-security-qa-modal.component.html',
  styleUrls: ['./change-security-qa-modal.component.scss']
})
export class ChangeSecurityQAModalComponent implements OnInit {

  public questions: object = {};
  public firstTime: boolean = true;
  public securityAnswer: string;
  public selectedQuestionId: number;

  constructor(private authService: AuthService, public dialogRef: MatDialogRef<ChangeSecurityQAModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private globalService: GlobalService) { }

  ngOnInit() {
    this.fetchSecurityQuestions();
  }

  private fetchSecurityQuestions() {
    this.authService.fetchAllSecurityQuestion()
      .subscribe(
        result => {
          if (result && result.response == "SUCCESS") {
            this.questions = result.security_questions.map((element: any) => {
              return { 'label': element[1], 'value': element[0] }
            });

            if (this.data && this.data.selectedQuestionId) {
              this.selectedQuestionId = this.data.selectedQuestionId;
              this.firstTime = false;
            }

          } else {
            // AlertHandler.ErrorAlert(result.message);
          }
        },
        error => {
          // AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
        });
  }


  onSaveClick() {
    if (this.securityAnswer && this.securityAnswer.trim() == '') {
      AlertHandler.WarningAlert(MessageHandler.WARNING_SECURITY_ANSWER_MISSING)
      return;
    }

    this.spinner.show();
    let user = {
      fullname: this.data.user.fullname,
      email: this.data.user.email,
      new_password: null,
      password: null
    }

    user['security_question_id'] = this.selectedQuestionId;
    user['security_answer'] = this.securityAnswer;

    this.authService.updateUser(user)
      .subscribe(
        result => {
          this.spinner.hide();
          if (result && result.response == "SUCCESS") {
            this.dialogRef.close();
            //Update User in Local Storage
            this.globalService.setLocalStorageItem('SECURITY_QUESTION_UPDATED', true);
          } else {
            AlertHandler.ErrorAlert(result.message);
          }
        },
        error => {
          this.spinner.hide();
          AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
        })
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
