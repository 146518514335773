import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ng-table',
  templateUrl: './ng-table.component.html',
  styleUrls: ['./ng-table.component.scss']
})
export class NgTableComponent implements OnInit {

  @Input() cols: any[];
  @Input() data: any[];
  @Input() loading: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
