import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer } from 'rxjs/observable/timer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-session-inactivity-modal',
  templateUrl: './session-inactivity-modal.component.html',
  styleUrls: ['./session-inactivity-modal.component.scss']
})
export class SessionInactivityModalComponent implements OnInit, OnDestroy {
  public counter = 0;
  private subscriptions = new Subscription();
  public logoutMsgDisplay: boolean = false;

  constructor(public dialogRef: MatDialogRef<SessionInactivityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.logoutMsgDisplay = false;
    this.counter = this.data.timeout;
    const source = timer(1, 1000);
    this.subscriptions.add(source.subscribe(val => this.checkForSessionExpiry()));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    console.log("Logging out the session.");
  }

  private checkForSessionExpiry() {
    this.counter = this.counter - 1;
    if (this.counter == 0) {
      this.logoutMsgDisplay = true;
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    }
  }

  closeTimer() {
      this.dialogRef.close({event:'Cancel'});
  }
}
