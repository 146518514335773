import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'negative-check' })
export class NegativeComparerPipe implements PipeTransform {

  transform(value: any): boolean {
    console.log("pipe csalled")
    return value >= 0 ;
  }
}
