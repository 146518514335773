import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) { }

  public login(data: any) {
    const url = `${environment.serverUrl}/auth/login`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public logout(data: any) {
    const url = `${environment.serverUrl}/auth/logout`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public ssoLogin(token: string) {
    const url = `${environment.serverUrl}/auth/sso-login/${token}`;
    return this.http.get(url)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public register(data: any) {
    const url = `${environment.serverUrl}/auth/register`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
          if (result && result.response == "SUCCESS") {
          }
        })
      );
  }

  public resetPassword(data: any) {
    const url = `${environment.serverUrl}/auth/reset-password`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
          if (result && result.response == "SUCCESS") {
          }
        })
      );
  }

  public validateSecurityAnswer(data: any) {
    const url = `${environment.serverUrl}/auth/validate-security-answer`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
          if (result && result.response == "SUCCESS") {
          }
        })
      );
  }

  public fetchUser() {
    const url = `${environment.serverUrl}/auth/fetch-user`;
    return this.http.get(url)
      .pipe(
        tap((result: any) => {
          if (result && result.response == "SUCCESS") {
          }
        })
      );
  }

  public fetchSecurityQuestion(username: string) {
    const url = `${environment.serverUrl}/auth/fetch-security-question/${username}`;
    return this.http.get(url)
      .pipe(
        tap((result: any) => {
          if (result && result.response == "SUCCESS") {
          }
        })
      );
  }

  public updateUser(data: any) {
    const url = `${environment.serverUrl}/auth/update-user`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public fetchAllSecurityQuestion() {
    const url = `${environment.serverUrl}/auth/fetch-all-security-question`;
    return this.http.get(url)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public srmLogin(username?: any) {
    const url = `${environment.serverUrl}/auth/srm-login`;
    return this.http.post(url, {"username" : username})
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public mslLogin(token: string, loginCurrentSystem?: boolean) {
    let data = { auth_code: token, loginCurrentSystem: loginCurrentSystem };
    const url = `${environment.serverUrl}/auth/msal-login`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
        })
      );
  }

  public updatePrivacyPolicy(resp: any) {
    let data = { "privacy_accepted": resp }
    const url = `${environment.serverUrl}/auth/privacy`;
    return this.http.post(url, data)
      .pipe(
        tap((result: any) => {
        })
      );
  }
}
