import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-privacy-statement-modal',
  templateUrl: './privacy-statement-modal.component.html',
  styleUrls: ['./privacy-statement-modal.component.scss']
})
export class PrivacyStatementModalComponent implements OnInit {

  public pdfSrc = window.location.origin + '/assets/files/Asterisk Supply Chain Solutions - Privacy Notice.pdf';
  constructor(public dialogRef: MatDialogRef<PrivacyStatementModalComponent>,
    private authService: AuthService,
    public globalService: GlobalService,
    private router: Router) { }

  ngOnInit() {
  }

  closeDialog() {
    this.authService.updatePrivacyPolicy(true).subscribe(resp => {
      if (resp.response === "SUCCESS") {
        this.dialogRef.close({ event: 'Cancel' });
      }
    })
  }

  /**
   * Action when a user logs out.
   *
   * @memberof AppHeader
   */
  logout() {
    this.authService.logout({})
      .subscribe(
        result => {
          if (environment.msalEnabled) {
            this.globalService.deleteUserSession();
            this.router.navigate(['auth/msl-logout']);
          } else {
            this.router.navigate(['auth/login']);
            this.globalService.deleteUserSession();
          }
          // if (result && result.response == "SUCCESS") {

          // } else {
          //   AlertHandler.ErrorAlert(result.message);
          //   this.router.navigate(['auth/login']);
          //   this.globalService.deleteUserSession();
          // }
        },
        error => {
          if (environment.msalEnabled) {
            this.globalService.deleteUserSession();
            this.router.navigate(['auth/msl-logout']);
          } else {
            this.router.navigate(['auth/login']);
            this.globalService.deleteUserSession();
          }
          // AlertHandler.ErrorAlert(MessageHandler.INTERNAL_ERROR);
        });
  }
}
