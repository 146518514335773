import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as pbi from 'powerbi-client';
import { models } from 'powerbi-client';
import { PowerBiEmbedService } from './power-bi-embed.service';

@Component({
  selector: 'app-power-bi-embed',
  templateUrl: './power-bi-embed.component.html',
  styleUrls: ['./power-bi-embed.component.scss']
})
export class PowerBiEmbedComponent implements OnInit {
  title = 'embed-power-bi';
  public screenHeight: number;
  @ViewChild('reportContainer') reportContainer: ElementRef;
  txtAccessToken: any;
  txtEmbedUrl: any;
  txtEmbedReportId: any;

  constructor(private dataService: PowerBiEmbedService, private spinner: NgxSpinnerService,) {

  }

  ngOnInit() {
    this.spinner.show();
    this.dataService.getDetails().subscribe(result => {
      this.txtAccessToken = result['token'];
      this.txtEmbedUrl = result['url']['embedUrl'];
      this.txtEmbedReportId = result['url']['ReportId'];
      // Read embed type from radio
      this.screenHeight = (window.screen.height);
      var token = this.txtAccessToken;
      this.spinner.hide();
      this.showReport(token);
    });


  }

  showReport(accessToken) {
    // Embed URL    
    // "&pageName=" + POWERBI.DASHBOARD_REPSECTION";
    //"&$filter=" + this.appConstants.pbiTransactionRepFilter + "'" + yourfilterName+ "'"; 
    //+ "&filterPaneEnabled=false" + "&navContentPaneEnabled=false"
    //"&$filter=Industries/Industry eq '" + filterValue + "'"
    let embedUrl = this.txtEmbedUrl ;
    let embedReportId = this.txtEmbedReportId;
    let config = {
      type: 'report',
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {}
    };
    let reportContainer = this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      console.log("Loaded");
    });
    report.on("error", () => {
      this.txtAccessToken;
    });
  }
}
